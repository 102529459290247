import { createSelector } from 'reselect';
import {
    ACTIVITY_TYPES,
    LOAD_STATUS_COMPLETE,
    LOAD_STATUS_PENDING,
    LOAD_STATUS_STALE,
    MODULES,
} from 'state-domains/constants';
import {
    AccountEAP,
    ActivityMap,
    CustomColour,
    Category,
    DefaultPermission,
    FilterOperator,
    List,
    MXColour,
    SystemFilter,
    Table,
    TableView,
    SelectedClearState,
    SampleResultsRankedUnit,
    ValidationRule,
} from 'state-domains/domain';

import { AsyncState, DataRowType, ShimState } from '../../../types';
import { isCompleteSelector, isFailedSelector, isPendingSelector } from '../../../utils';
import {
    Account,
    ActivityGroupMap,
    CustomExportInfo,
    ExportTemplate,
    Header,
    HeaderType,
    SampleDispatchHeader,
    SampleWorkflow,
    SubscriptionState,
    UserWithType,
} from '../types';

export const emptySubscriptionState = {
    selectedClearState: {
        selectedItems: {},
        unSelectedItems: {},
        isSelectedAll: false,
        isClearedAll: false,
        total: 0,
        selectedCount: 0,
        status: LOAD_STATUS_COMPLETE,
        error: null,
    },
    subscriptionDataState: { status: LOAD_STATUS_PENDING, error: null },
    relatedCollectionsState: { status: LOAD_STATUS_COMPLETE, error: null },
    relatedCollections: {},
    singleItemLoadState: { status: LOAD_STATUS_STALE, error: null },
    subscriptions: [],
    activities: {},
    activityCategories: {},
    validationRuleCategories: {},
    account: {} as Account,
    users: {},
    tableViews: {},
    lists: {},
    listCategories: {},
    tables: {},
    tableCategories: {},
    headers: {},
    headerCategories: {},
    headerTypes: {},
    status: LOAD_STATUS_PENDING,
    error: null,
    systemFilters: {},
    filterOperators: {},
    defaultPermissions: {},
    coordinates: {},
    activityGroups: {},
    customColours: {},
    mxColours: {},
    units: [],
    sampleDispatchHeaders: {},
    sampleWorkflows: {},
    exportTemplates: {},
    customExports: {},
    validationRules: {},
};

const subscription = (state: Partial<ShimState>): SubscriptionState => {
    const { subscription: subSubscription = { ...emptySubscriptionState } } = state || {};
    return subSubscription;
};

const subDataState = createSelector(
    subscription,
    ({ subscriptionDataState = {} as AsyncState }: SubscriptionState): AsyncState =>
        subscriptionDataState,
);

const activities = createSelector(
    subscription,
    ({ activities: subActivities = {} }: SubscriptionState): Record<string, ActivityMap> =>
        subActivities,
);

const activity = (id: string) =>
    createSelector(activities, (subActivities: Record<string, ActivityMap>): string =>
        subActivities[id] ? subActivities[id].name : '',
    );

const activityCategories = createSelector(
    subscription,
    ({
        activityCategories: subActivityCategories = {},
    }: SubscriptionState): Record<string, Category> => subActivityCategories,
);

const validationRuleCategories = createSelector(
    subscription,
    ({
        validationRuleCategories: subValidationRuleCategories = {},
    }: SubscriptionState): Record<string, Category> => subValidationRuleCategories,
);

const activityObject = (id: string) =>
    createSelector(
        activities,
        (subActivities: Record<string, ActivityMap>): ActivityMap => subActivities[id] || {},
    );

const activitiesById = (ids: string[]) =>
    createSelector(
        activities,
        (subActivities: Record<string, ActivityMap>): Record<string, ActivityMap> =>
            Object.keys(subActivities)
                .filter((key) => ids.includes(key))
                .reduce((res, key) => {
                    res[key] = subActivities[key];
                    return res;
                }, {} as any) || {},
    );

const users = createSelector(
    subscription,
    ({ users: subUsers = {} }: SubscriptionState): Record<string, UserWithType> => subUsers,
);

const user = (id: string) =>
    createSelector(users, (subUsers = {}): UserWithType | undefined => subUsers[id] || {});

const tableViews = createSelector(
    subscription,
    ({ tableViews: subTableViews = {} }: SubscriptionState): Record<string, TableView> =>
        subTableViews,
);

const tableView = (id: string) =>
    createSelector(
        tableViews,
        (subTableview: Record<string, TableView>): TableView => subTableview[id] ?? {},
    );

const tables = createSelector(
    subscription,
    ({ tables: subTables = {} }: SubscriptionState): Record<string, Table> => subTables,
);

const tableCategories = createSelector(
    subscription,
    ({ tableCategories: subTableCategories = {} }: SubscriptionState): Record<string, Category> =>
        subTableCategories,
);

const table = (id: string) =>
    createSelector(tables, (subTables: Record<string, Table>): Table => subTables[id]);

const lists = createSelector(
    subscription,
    ({ lists: subLists = {} }: SubscriptionState): Record<string, List> => subLists,
);

const listCategories = createSelector(
    subscription,
    ({ listCategories: subListCategories = {} }: SubscriptionState): Record<string, Category> =>
        subListCategories,
);

const list = (listId: string) =>
    createSelector(lists, (subLists: Record<string, List>): List => subLists[listId] ?? {});

const headers = createSelector(
    subscription,
    ({ headers: subHeaders = {} }: SubscriptionState): Record<string, Header> => subHeaders,
);

const header = (headerId: string) =>
    createSelector(
        headers,
        (subHeaders: Record<string, Header>): Header => subHeaders[headerId] ?? {},
    );
const headerCategories = createSelector(
    subscription,
    ({ headerCategories: subHeaderCategories = {} }: SubscriptionState): Record<string, Category> =>
        subHeaderCategories,
);

const headerTypes = createSelector(
    subscription,
    ({ headerTypes: subHeaderTypes = {} }: SubscriptionState): Record<string, HeaderType> =>
        subHeaderTypes,
);

const systemFilters = createSelector(
    subscription,
    ({ systemFilters = {} }: SubscriptionState): Record<string, SystemFilter> => systemFilters,
);

const systemFiltersForModule = (moduleId: string) =>
    createSelector(systemFilters, (filters: Record<string, SystemFilter>): SystemFilter[] =>
        Object.values(filters).filter((x) => x.modules[moduleId]),
    );
const systemFiltersForCollarModules = (moduleIds: string[], excludedFilters: string[] = []) =>
    createSelector(
        systemFilters,
        (filters: Record<string, SystemFilter>): Record<string, SystemFilter[]> => {
            const result = {
                [ACTIVITY_TYPES.DRILLING]: [] as SystemFilter[],
                [ACTIVITY_TYPES.POINT]: [] as SystemFilter[],
            };
            moduleIds.forEach((id) => {
                if (id === MODULES.DRILL_HOLES)
                    result[ACTIVITY_TYPES.DRILLING] = Object.values(filters).filter(
                        (x) => x.modules[id] && !excludedFilters.includes(x.id),
                    );
                else if (id === MODULES.POINT_SAMPLES)
                    result[ACTIVITY_TYPES.POINT] = Object.values(filters).filter(
                        (x) => x.modules[id] && !excludedFilters.includes(x.id),
                    );
            });
            return result;
        },
    );
const filterOperators = createSelector(
    subscription,
    ({ filterOperators = {} }: SubscriptionState): Record<string, FilterOperator> =>
        filterOperators,
);

const filterOperatorByType = (type: string) =>
    createSelector(
        filterOperators,
        (filterOperators: Record<string, FilterOperator>): FilterOperator => filterOperators[type],
    );

const defaultPermissions = createSelector(
    subscription,
    ({ defaultPermissions = {} }: SubscriptionState): Record<string, DefaultPermission> =>
        defaultPermissions,
);

const defaultPermission = (id: string) =>
    createSelector(
        defaultPermissions,
        (defaultPermissions: Record<string, DefaultPermission>): DefaultPermission =>
            defaultPermissions[id],
    );

const defaultPermissionForCollarModules = (ids: string[]) =>
    createSelector(
        defaultPermissions,
        (
            defaultPermissions: Record<string, DefaultPermission>,
        ): Record<string, DefaultPermission> => {
            const result = {
                [ACTIVITY_TYPES.DRILLING]: {} as DefaultPermission,
                [ACTIVITY_TYPES.POINT]: {} as DefaultPermission,
            };
            ids.forEach((id) => {
                if (id === MODULES.DRILL_HOLES)
                    result[ACTIVITY_TYPES.DRILLING] = defaultPermissions[id];
                else if (id === MODULES.POINT_SAMPLES)
                    result[ACTIVITY_TYPES.POINT] = defaultPermissions[id];
            });
            return result;
        },
    );

const activityGroups = createSelector(
    subscription,
    ({ activityGroups = {} }: SubscriptionState): Record<string, string> => {
        const dict: Record<string, string> = {};
        Object.values(activityGroups).forEach((element: ActivityGroupMap) => {
            dict[element.id] = element.name;
        });
        return dict;
    },
);

const activityGroupsObject = createSelector(
    subscription,
    ({ activityGroups = {} }: SubscriptionState): Record<string, ActivityGroupMap> =>
        activityGroups,
);

const mxColours = createSelector(
    subscription,
    ({ mxColours = {} }: SubscriptionState): Record<string, MXColour> => mxColours,
);

const rankedColumnUnits = createSelector(
    subscription,
    ({ units = [] }: SubscriptionState): SampleResultsRankedUnit[] => units,
);

const customColours = createSelector(
    subscription,
    ({ customColours = {} }: SubscriptionState): Record<string, CustomColour> => customColours,
);

const account = createSelector(
    subscription,
    ({ account = {} as Account }: SubscriptionState): Account => account,
);

const isEAP = createSelector(
    account,
    ({ eap = {} as AccountEAP }: Account): boolean => eap.accessNewApp ?? false,
);

const getSubscriptionResource = (id: string, type: string) =>
    createSelector(subscription, (subs: SubscriptionState) => (subs as any)[type]?.[id] ?? {});

const unSelectedItems = createSelector(
    subscription,
    ({
        selectedClearState = {} as SelectedClearState,
    }: SubscriptionState): Record<string, DataRowType> => selectedClearState.unSelectedItems,
);

const selectedItems = createSelector(
    subscription,
    ({
        selectedClearState = {} as SelectedClearState,
    }: SubscriptionState): Record<string, DataRowType> => selectedClearState.selectedItems,
);

const isSelectedAll = createSelector(
    subscription,
    ({ selectedClearState = {} as SelectedClearState }: SubscriptionState): boolean =>
        selectedClearState.isSelectedAll,
);

const isClearedAll = createSelector(
    subscription,
    ({ selectedClearState = {} as SelectedClearState }: SubscriptionState): boolean =>
        selectedClearState.isClearedAll,
);

const totalItems = createSelector(
    subscription,
    ({ selectedClearState = {} as SelectedClearState }: SubscriptionState): number =>
        selectedClearState.total,
);

const selectedCount = createSelector(
    subscription,
    ({ selectedClearState = {} as SelectedClearState }: SubscriptionState): number =>
        selectedClearState.selectedCount,
);

const sampleDispatchHeaders = createSelector(
    subscription,
    ({
        sampleDispatchHeaders = {} as Record<string, SampleDispatchHeader>,
    }: SubscriptionState): Record<string, SampleDispatchHeader> => sampleDispatchHeaders,
);

const sampleWorkflows = createSelector(
    subscription,
    ({
        sampleWorkflows = {} as Record<string, SampleWorkflow>,
    }: SubscriptionState): Record<string, SampleWorkflow> => sampleWorkflows,
);

const relatedCollectionsState = createSelector(
    subscription,
    ({ relatedCollectionsState = {} as AsyncState }: SubscriptionState): AsyncState =>
        relatedCollectionsState,
);

const singleItemLoadState = createSelector(
    subscription,
    ({ singleItemLoadState = {} as AsyncState }: SubscriptionState): AsyncState =>
        singleItemLoadState,
);

const coordinates = createSelector(
    subscription,
    ({ coordinates: coords = {} }: SubscriptionState): Record<string, any> => coords,
);

const coordinate = (id: string) =>
    createSelector(coordinates, (subCoords: Record<string, any>): any => subCoords[id] ?? {});

const exportTemplates = createSelector(
    subscription,
    ({ exportTemplates }: SubscriptionState): Record<string, ExportTemplate> => exportTemplates,
);
const customExports = createSelector(
    subscription,
    ({ customExports }: SubscriptionState): Record<string, CustomExportInfo> => customExports,
);

// Validation Rules
const validationRules = createSelector(
    subscription,
    ({
        validationRules = {} as Record<string, ValidationRule>,
    }: SubscriptionState): Record<string, ValidationRule> => validationRules,
);

const validationRuleById = (id: string | undefined) =>
    createSelector(
        validationRules,
        (validationRules): Partial<ValidationRule> | null => (id && validationRules[id]) || null,
    );

const relatedCollections = <T>() =>
    createSelector(
        subscription,
        ({ relatedCollections }: SubscriptionState): T => relatedCollections as T,
    );

export const selectors = {
    selectedItems,
    unSelectedItems,
    isSelectedAll,
    isClearedAll,
    totalItems,
    selectedCount,
    subscription,
    activityObject,
    isSubscriptionDataStatePending: isPendingSelector(subDataState),
    isSubscriptionDataStateComplete: isCompleteSelector(subDataState),
    isSubscriptionDataStateFailed: isFailedSelector(subDataState),
    isRelatedCollectionsStatePending: isPendingSelector(relatedCollectionsState),
    isRelatedCollectionsStateComplete: isCompleteSelector(relatedCollectionsState),
    isRelatedCollectionsStateFailed: isFailedSelector(relatedCollectionsState),
    isSingleItemLoadStatePending: isPendingSelector(singleItemLoadState),
    isSingleItemLoadStateComplete: isCompleteSelector(singleItemLoadState),
    isSingleItemLoadStateFailed: isFailedSelector(singleItemLoadState),
    activities,
    activity,
    activityCategories,
    validationRuleCategories,
    users,
    user,
    tableViews,
    tableView,
    tables,
    tableCategories,
    lists,
    list,
    listCategories,
    header,
    headers,
    headerCategories,
    headerTypes,
    table,
    activitiesById,
    systemFilters,
    systemFiltersForModule,
    filterOperators,
    filterOperatorByType,
    defaultPermissions,
    defaultPermission,
    activityGroups,
    activityGroupsObject,
    mxColours,
    customColours,
    account,
    isEAP,
    defaultPermissionForCollarModules,
    systemFiltersForCollarModules,
    getSubscriptionResource,
    rankedColumnUnits,
    sampleDispatchHeaders,
    sampleWorkflows,
    coordinates,
    coordinate,
    exportTemplates,
    customExports,
    validationRules,
    validationRuleById,
    relatedCollections,
};
